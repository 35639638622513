import { SubscriptionPlanInterval, SubscriptionPlanStripe, TicketType } from 'generated/graphql';

type RecurringSubscriptionPlan = Omit<SubscriptionPlanStripe, 'interval'> & {
  interval: Exclude<SubscriptionPlanInterval, typeof SubscriptionPlanInterval.Days_7>;
};

/**
 * A type guard to check if the subscription plan is supported
 * This is used to avoid potential runtime errors when a new subscription plan is added to the Audience API in the future
 */
export function isSupportedSubscriptionPlan(
  subscriptionPlan: SubscriptionPlanStripe,
): subscriptionPlan is SubscriptionPlanStripe {
  const supportedPlans = [
    SubscriptionPlanInterval.Days_7,
    SubscriptionPlanInterval.Monthly,
    SubscriptionPlanInterval.Yearly,
  ];

  return supportedPlans.includes(subscriptionPlan.interval);
}

/**
 * A filter for recurring subscription plans (as opposed to one-time purchases)
 */
export function isRecurringSubscriptionPlan(
  subscriptionPlan: SubscriptionPlanStripe,
): subscriptionPlan is RecurringSubscriptionPlan {
  return subscriptionPlan.ticketType === TicketType.Subscription;
}

/**
 * Sort the subscription plans by interval because the Audience API does not guarantee the order
 */
export function sortSubscriptionPlans(a: SubscriptionPlanStripe, b: SubscriptionPlanStripe): number {
  const subscriptionPlanIntervalOrder = [
    SubscriptionPlanInterval.Days_7,
    SubscriptionPlanInterval.Monthly,
    SubscriptionPlanInterval.Yearly,
  ];

  return subscriptionPlanIntervalOrder.indexOf(a.interval) - subscriptionPlanIntervalOrder.indexOf(b.interval);
}
